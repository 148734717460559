<template>
  <div>
    <Loading :msg="$i18n.t('games.room_join.connection')" />
  </div>
</template>

<script>
import Loading from "../../../components/loading/Loading.vue";
import RoomConfigurationService from "../../../services/roomConfigurationService";
import {ROUTE_HOME} from "../../../router";

export default {
  name: "RoomConnection",
  components: {Loading},
  async mounted() {
    const user = this.$store.getters.getUser;
    try {
      await RoomConfigurationService.addUserIntoRoom(
        this.$route.query.pin,
        user
      );
    } catch (error) {
      await this.$router.push({name: ROUTE_HOME.name});
      return;
    }
    await RoomConfigurationService.connectUserToSocketRoom(this.$socket.client, user, this.$route.query.pin);
    await RoomConfigurationService.clearTimeout(this.$socket.client);
  }
};
</script>
